.checkbox {
  max-width: 28px;
  min-width: 28px;
  width: 28px;
}

.status {
  max-width: 120px;
  min-width: 120px;
  width: 120px;
}

.lastChange {
  max-width: 85px;
  min-width: 85px;
  width: 85px;
}

.actions {
  max-width: 140px;
  min-width: 140px;
  width: 140px;
}

.icons {
  max-width: 56px;
  min-width: 32px;
  width: 32px;
}
