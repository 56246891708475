.new_envelope_button {
  width: 10rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-lg);
  border-color: var(--warning-500);

  &:hover {
    border-color: var(--warning-600);
  }

  &:active,
  &:focus {
    border-color: var(--warning-500);
  }
}
