.content {
  margin-right: 5rem;
  max-width: 25rem;
}

.error_code {
  font-size: 2.25rem;
  line-height: 1.75rem;
}

.link {
  &,
  &:active,
  &:focus,
  &:hover {
    color: var(--light);
  }
}
