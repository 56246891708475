.directions, .flowState {
  max-width: 24px;
  min-width: 24px;
  width: 24px;
}

.labels {
  display: block;
  min-width: 6rem;
  max-width: 6rem;
}

.actionButton {
  width: 120px;
  & > button {
    min-width: 90px;
    max-width: 90px;
  }
}

.recipients {
  grid-template-columns: auto 1fr !important;
  & div .tooltip {
    border: none;
  }
}

.tooltip {
  top: 50% !important;
  transform: translate(72px, -50%);
  border-radius: 2px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -9px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-right: 10px solid var(--light);
    border-bottom: 10px solid transparent;
  }
  &_item {
    list-style: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -9px;
      width: 3px;
      height: 3px;
      transform: translateY(-50%);
      border-radius: 50%;
      background: var(--text-color);
    }
  }
  &_button {
    padding-top: 1px;
  }
}

tr > td {
  vertical-align: middle;
}

.subject {
  &_container {
  min-width: 215px;
  max-width: 215px;
  }
}
